import React, { createContext, useState } from "react";
import { AuthState, Telefone } from "../types";

interface AuthProviderProps {
  children: React.ReactNode;
}

const defaultState: AuthState = {
  setTelefone: (telefones: Telefone[]) => {},
  telefone: [],
  setIp: (ip: string) => {},
  ip: "",
  setEmail: (email: string) => {},
  email: "",
};

export const AuthContext = createContext(defaultState);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [telefone, setTelefoneState] = useState<Telefone[]>([]);
  const [ip, setIpState] = useState<string>("");
  const [email, setEmailState] = useState<string>("");

  function setTelefone(telefones: Telefone[]) {
    setTelefoneState(telefones);
  }
  function setIp(ip: string) {
    setIpState(ip);
  }
  function setEmail(email: string) {
    setEmailState(email);
  }

  return (
    <AuthContext.Provider value={{ setTelefone, telefone, setIp, ip, setEmail, email }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;