import { useContext, useEffect, useState } from "react";
import { Email, InfosLoja, NomeLoja } from "../shared/types";
import { InfosLoja as InfosLojaDictionary, Telefone as TelefoneDictionary, Email as EmailDictionary, NomeLoja as NomeLojaDictionary, DescLoja as DescLojaDictionary } from "../shared/utils/dictionary";

import logoLitoral from '../assets/logoLitoral.png'
import iconEmail from '../assets/iconEmail.png'
import iconHorario from '../assets/iconHorario.png'
import iconWhats from '../assets/iconWhats.png'
import { getEmails, getInfosLoja, getLoja, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { isMobile } from "../shared/utils/javascript";
import { useNavigate } from "react-router-dom";

export const Footer = () => {

    const { telefone, ip } = useContext(AuthContext);
    const navigate = useNavigate();

    const [loja, setLoja] = useState<InfosLoja>();
    const [email, setEmail] = useState<Email[]>([])
    const [nomeLoja, setNomeLoja] = useState<NomeLoja>()

    const handleGetInfosLoja = async () => {
        const result = await getInfosLoja()
        setLoja(result?.data)
    }

    useEffect(() => {
        handleGetInfosLoja();
    },[])

    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")

    const handleGetNomeLoja = async () => {
        try {
            const result = await getLoja()
            setNomeLoja(result?.data.retorno ?? {
                nome_empresa: ''
            }) 
        } catch {
            //
        }
    }
    const handleGetEmail = async () => {
        try {
            const result = await getEmails()
            setEmail(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    useEffect(() => {
        handleGetEmail();
        handleGetNomeLoja();
    },[])

    return (
        <div className="bg-secondary text-accent">
            <div className="py-8 px-28 flex flex-row justify-between gap-10 mobile:flex-col mobile:px-3 mobile:items-center">
                <div className="flex gap-3">
                    <img src={iconWhats} className="max-h-10" alt="Whatsapp" />
                    <div className="cursor-pointer" onClick={() => {
                            postWhats({
                                ip: ip,
                                dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                codVeiculo: 0
                            })
                            window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                        }}>
                        <h2 className="font-light">Whatsapp:</h2>
                        <h2>{telefoneWhats?.[TelefoneDictionary.TELEFONE]}</h2>
                    </div>
                </div>
                <div className="flex gap-3">
                    <img src={iconEmail} className="max-h-10" alt="E-mail" />
                    <div className="cursor-pointer" onClick={() => window.open(`mailto:${email[0][EmailDictionary.EMAIL]}`, "_blank")}>
                        <h2 className="font-light">E-mail:</h2>
                        <h2>{email?.[0]?.[EmailDictionary.EMAIL]}</h2>
                    </div>
                </div>
                <div className="flex gap-3">
                    <img src={iconHorario} className="max-h-10" alt="Whatsapp" />
                    <div>
                        <h2 className="font-light">Horário de atendimento:</h2>
                        <div className="flex flex-col">
                            <h2><span className="font-light">Seg. a Sex.:</span>{loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.HORARIO_SEG]}</h2>
                            <h2><span className="font-light">Sab.:</span>{loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.HORARIO_SABADO]}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-neutral py-2 flex items-center justify-center">
                {isMobile() ? (
                    <div className="flex flex-col items-center px-3">
                        <h4 className="flex items-center gap-2 leading-5">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por</h4>
                        <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover mt-[-22px] ml-8" src={logoLitoral} alt="Litoral Car" />
                        <span onClick={() => navigate('/termos')} className="underline mt-3">Politica de privacidade e termos de uso</span>
                    </div>
                ) : (
                    <h4 className="flex items-center gap-2">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover" src={logoLitoral} alt="Litoral Car" /> <span onClick={() => navigate('/termos')} className="underline">Politica de privacidade e termos de uso</span></h4>
                )}
            </div>
        </div>
    )
}