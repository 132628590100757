import { getInfosLoja, getLoja } from '../../service/api';
import { useEffect, useState } from 'react';
import { NomeLoja } from '../../shared/types';
import { NomeLoja as NomeLojaDictionary } from '../../shared/utils/dictionary';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export const HeaderAdmin = () => {

    const [infosLojaResult, setInfosLojaResult] = useState<NomeLoja>()
    const navigate = useNavigate()

    const infosLoja = async () => {
        try {
            const result = await getLoja();
            setInfosLojaResult(result?.data.retorno)
        } catch {

        }
    }

    useEffect(() => {
        infosLoja()
    },[])
      
    return (
        <div className='bg-primary w-full h-20 flex justify-between items-center px-5'>
            <a href='/admin' className='text-white text-lg'>{infosLojaResult?.[NomeLojaDictionary.NOME_LOJA]}</a>
            <div tabIndex={0} role="button" onClick={() => {
                Cookies.remove('token');
                navigate('/admin')
            }} className="btn m-1 bg-transparent border-none hover:bg-transparent text-white"><i className="ri-logout-box-line ri-xl"></i></div>
        </div>
    )
}