import { Carousel } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { Endereco, Slider, Veiculo } from "../shared/types";
import { FiltroEstoque } from "../components/filtroEstoque";
import { CardVeiculo } from "../components/cardVeiculo";
import { useNavigate } from "react-router-dom";

import iconLocalizacao from '../assets/ICON_LOCALIZAÇÃO.png'
import iconFinanciamento from '../assets/ICON_FINANCIAMENTO.png'

import { buscarSlide, getEnderecos, getEstoque, postEmail } from "../service/api";
import { Endereco as EnderecoDictionary } from "../shared/utils/dictionary";
import { AuthContext } from "../shared/context/auth";

const Home: React.FC = () => {

    const { email } = useContext(AuthContext);
    const navigate = useNavigate()

    const optionsCondicao = ["Condições que você precisa", "Entrada parcelada", "Mais prazo", "Menor parcela", "Financiar sem CNH"]
    const [slide, setSlide] = useState<Slider[]>([]);
    const [estoque, setEstoque] = useState<Veiculo[]>([]);
    const [endereco, setEndereco] = useState<Endereco[]>([])
    const [formEnvio, setFormEnvio] = useState({
        nome: "",
        telefone: "",
        cpf: "",
        email: "",
        condicao: ""
    })
    const estilos = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "bg-accent hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800 hidden",
            on: "bg-primary dark:bg-primary hidden"
          },
          base: "h-2 w-2 rounded-full",
          wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white group-hover:bg-white/80 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-accent/30 dark:group-hover:bg-accent/60 dark:group-focus:ring-accent/70 sm:h-10 sm:w-10",
          icon: "h-5 w-5 text-primary dark:text-primary sm:h-6 sm:w-6"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };

    const estilosHiddenControls = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "hidden",
            on: "hidden"
          },
          base: "hidden",
          wrapper: "hidden"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "hidden",
          icon: "hidden"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };

    const handleGetSlide = async () => {
        const result = await buscarSlide();
        setSlide(result?.data)
    }

    const handleGetEstoque = async () => {
    try {
        const result = await getEstoque()
        setEstoque(result?.data.retorno ?? []) 
    } catch {
        //
    }
    }

    const handleGetEndereco = async () => {
        try {
            const result = await getEnderecos()
            setEndereco(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    useEffect(() => {
        handleGetSlide();
        handleGetEstoque();
        handleGetEndereco();
    },[])

    const handleEmail = () => {
        postEmail({
            to: email,
            replyTo: formEnvio.email,
            subject: "Financiamento",
            html: {
                nome: formEnvio.nome,
                telefone: formEnvio.telefone,
                email: formEnvio.email,
                cpf: formEnvio.cpf,
                condicao: formEnvio.condicao,
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
          })
          setFormEnvio({
            nome: "",
            telefone: "",
            cpf: "",
            email: "",
            condicao: ""
        })
    }

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    const findBanner = () => {
        const mobile = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
        const desktop = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 

        return isMobile() && mobile?.length > 0 ? mobile : desktop
    }

    return (
        <div className="bg-primary">
            {/* Carrossel */}
            <div className="h-[64vh] mobile:h-[30vh] mobile:relative">
                <Carousel theme={findBanner().length <= 1 ? estilosHiddenControls : estilos}>
                {findBanner()?.map((x: Slider) => (
                    <img key={x.id} className='object-cover h-full' src={`${process.env.REACT_APP_FOTO}fotos/${x.arquivo}`} alt="..." />
                ))}
                </Carousel>
            </div>

            {/* Filtro */}
            <div className="bg-secondary py-7 flex justify-center items-center w-full">
                <FiltroEstoque />
            </div>

            {/* Estoque */}
            <div className="py-12 flex flex-col justify-center items-center text-center text-accent gap-3">
                <h1 className="font-bold text-3xl mobile:text-xl">DESTAQUES NA KAIO MOTORS VEÍCULOS</h1>
                <h3 className="font-semibold">Os melhores preços com as melhores condições!</h3>
            </div>
            <div className="flex flex-col gap-8 justify-center items-center pb-6 mobile:pb-2 mobile:gap-8">
                <div className="grid grid-cols-4 px-8 gap-11 mobile:grid-cols-1 mobile:px-3 mobile:gap-5">
                    {estoque?.slice(0,8).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <button onClick={() => navigate('/estoque')} className="btn btn-info rounded-none text-accent w-fit px-20">ESTOQUE COMPLETO</button>
            </div>

            {/* Mapa e financeamento */}
            <div className="pt-3 flex flex-row text-accent mobile:flex-col">
                <div className="w-full flex bg-bgDivLocalizacao justify-center items-center flex-col gap-20 min-h-[650px]">
                    <img className="max-h-16" src={iconLocalizacao} alt="Localização" />
                    <h1 className="font-bold text-3xl mt-[-30px]">NOSSA LOCALIZAÇÃO</h1>
                    <div className="flex flex-col items-center">
                        <h3 className="font-bold text-xl">{endereco?.[0]?.[EnderecoDictionary.LOGRADOURO]}</h3>
                        <h3 className="font-bold text-xl">{endereco?.[0]?.[EnderecoDictionary.BAIRRO]} - {endereco?.[0]?.[EnderecoDictionary.CIDADE]}, {endereco?.[0]?.[EnderecoDictionary.UF]}</h3>
                    </div>
                    <button className="btn btn-info text-accent font-bold px-32 h-10 min-h-0 rounded-none" onClick={() => navigate("/contato")}>MAPA</button>
                </div>
                <div className="w-full flex bg-bgDivFinanciamento justify-center items-center flex-col gap-8 min-h-[650px] text-center">
                    <img className="max-h-16" src={iconFinanciamento} alt="Financiamento" />
                    <h1 className="font-bold text-3xl">SIMULE SEU FINANCIAMENTO</h1>
                    <div className="flex flex-col items-center gap-3 min-w-96 text-black">
                        <input onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                nome: event.target.value
                                }));
                        }} type="text" value={formEnvio.nome} placeholder="Seu nome" className="input input-bordered rounded-none w-full" />
                        <input onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                telefone: event.target.value
                                }));
                        }} type="text" value={formEnvio.telefone} placeholder="Seu telefone" className="input input-bordered rounded-none w-full" />
                        <input onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                cpf: event.target.value
                                }));
                        }} type="text" value={formEnvio.cpf} placeholder="Seu CPF" className="input input-bordered rounded-none w-full" />
                        <input onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                email: event.target.value
                                }));
                        }} type="text" value={formEnvio.email} placeholder="Seu e-mail" className="input input-bordered rounded-none w-full" />
                        <select onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                condicao: event.target.value
                            }));
                        }} className="select select-bordered w-full rounded-none text-black border-none">
                            {optionsCondicao.map((x, i) => (
                            <option key={i}>{x}</option>
                        ))}
                    </select>
                        <button className="btn btn-info text-accent font-bold px-32 h-10 min-h-0 rounded-none w-full" onClick={() => handleEmail()}>ENVIAR</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;