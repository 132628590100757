import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { postLogin } from '../../service/api';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const loginCookies = Cookies.get('token');

    useEffect(()=> {
        if(loginCookies) {
            navigate('/admin/home')
        }
    },[])

    useEffect(()=> {
        if(loginCookies) {
            navigate('/admin/home')
        }
    },[])

    async function handleLogin() {
        try {
            const result = await postLogin({
                login: login,
                senha: password
            })
                if(result?.status === 200) {
                    Cookies.set('token', result.data, { expires: 30 / (24 * 60) });
                    navigate('/admin/home')
                    window.location.reload()
                } else {
                    Cookies.remove('token');
                }
        } catch {
            //
        }
      }

    return (
        <div className='h-screen justify-center flex flex-col gap-4 items-center'>
            <h2 className='text-xl font-medium'>Login</h2>
            <input className="input input-bordered w-1/5" onChange={(e) => {setLogin(e.target.value)}} placeholder='Usuario' />
            <input onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                    handleLogin();
                    }
            }} className="input input-bordered w-1/5" type='password' onChange={(e) => {setPassword(e.target.value)}} placeholder='Senha' />
            <button className='btn btn-primary' onClick={() => {handleLogin()}} type='button'>Entrar</button>
        </div>
    )
}