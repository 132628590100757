import { useNavigate } from "react-router-dom"
import { Veiculo } from "../shared/types"
import { Veiculo as VeiculoDictionary } from "../shared/utils/dictionary"
import { ModalProposta } from "./modalProposta"
import semImagem from '../assets/semImagem.png'
import iconAno from '../assets/ICON_ANO.png'
import iconKm from '../assets/ICON_KM.png'

type CardVeiculoType = {
    veiculo: Veiculo
}

export const CardVeiculo = ({ veiculo }: CardVeiculoType) => {

    const navigate = useNavigate();

    const img = `https://litoralcar.com.br/foto-resize-site/X/${veiculo?.[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo?.[VeiculoDictionary.FOTOS][0]}`

    const handleNavigate = () => {
        const paramsObj = { 
            cod_veiculo: veiculo[VeiculoDictionary.COD_VEICULO],
            marca: veiculo[VeiculoDictionary.MARCA],
            modelo: veiculo[VeiculoDictionary.MODELO],
            versao: veiculo[VeiculoDictionary.VERSAO]
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/veiculo?params=${paramsString}`;
        navigate(urlComObjeto)
    }

    return (
        <div className="flex flex-col bg-secondary text-accent rounded-xl mobile:min-w-[95vw]" style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"}}>
            <ModalProposta veiculo={veiculo} />
            {veiculo[VeiculoDictionary.FOTOS].length === 0 ? (
                <img src={semImagem} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover rounded-t-xl" onClick={handleNavigate} />
            ) : (
                <img src={img} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover rounded-t-xl" onClick={handleNavigate} />
            )}
            <div className="p-4 flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                    <h2><span className="font-semibold">{veiculo[VeiculoDictionary.MARCA]} {veiculo[VeiculoDictionary.MODELO]}</span></h2>
                    <h2>{veiculo[VeiculoDictionary.VERSAO]}</h2>
                </div>
                    <div className="flex flex-row items-end gap-10 py-3">
                        <h2 className="flex items-center gap-3"><img className="max-h-4" src={iconAno} alt="Ano" /> {veiculo[VeiculoDictionary.ANO]}</h2>
                        <h2 className="flex items-center gap-3"><img className="max-h-4" src={iconKm} alt="Quilometragem" /> {Number(veiculo[VeiculoDictionary.QUILOMETRAGEM]).toLocaleString()}Km</h2>
                    </div>
                <div className="flex justify-between items-center">
                    <h1 className="font-semibold">
                        {veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    </h1>
                    <div className="flex gap-2 items-center">
                        <button className='btn btn-info text-accent rounded-none px-5 max-h-min h-min min-h-min py-2' onClick={() => handleNavigate()}>Saiba mais</button>
                    </div>
                </div>
            </div>
        </div>
    )
}